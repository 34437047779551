<template>
  <div>
    <b-card title="Filtros">
      <b-card-text>
        <div class="row">
          <!-- <div class="col-6 pt-4">
            <b-checkbox switch v-model="filtros.dt_vencimento"
              >Data Vencimento</b-checkbox
            >
            <div v-if="filtros.dt_vencimento">
              <v-date-picker
                v-model="filtros.dt_vencimento_range"
                is-range
                class="text-center"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="flex justify-center items-center">
                    <input
                      :value="`${inputValue.start} - ${inputValue.end}`"
                      v-on="inputEvents.start"
                      class="form-control"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
          <div class="col-6 pt-4">
            <b-checkbox switch v-model="filtros.dt_competencia"
              >Data Competencia</b-checkbox
            >
            <div v-if="filtros.dt_competencia">
              <v-date-picker
                v-model="filtros.dt_competencia_range"
                is-range
                class="text-center"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="flex justify-center items-center">
                    <input
                      :value="`${inputValue.start} - ${inputValue.end}`"
                      v-on="inputEvents.start"
                      class="form-control"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div> -->
          <div class="col-12 col-lg-4 pt-4 text-left text-center-lg">
            <b-checkbox
              switch
              v-model="filtros.dt_lancamento"
              >Data Lançamento</b-checkbox
            >
            <div v-if="filtros.dt_lancamento">
              <v-date-picker
                v-model="filtros.dt_lancamento_range"
                is-range
                class="text-center"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="flex justify-center items-center">
                    <input
                      :value="`${inputValue.start} - ${inputValue.end}`"
                      v-on="inputEvents.start"
                      class="form-control"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
          <div class="col-12 col-lg-4 pt-4 text-left  text-center-lg">
            <b-checkbox
              switch
              v-model="filtros.dt_conciliado"
              >Data Conciliado
            </b-checkbox>
            <div v-if="filtros.dt_conciliado">
              <v-date-picker
                v-model="filtros.dt_conciliado_range"
                is-range
                class="text-center"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="flex justify-center items-center">
                    <input
                      :value="`${inputValue.start} - ${inputValue.end}`"
                      v-on="inputEvents.start"
                      class="form-control"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
          <div class="col-12 col-lg-4 pt-4  text-left  text-center-lg">
            <b-checkbox
              switch
              v-model="filtros.dt_competencia"
              >Data Competencia
            </b-checkbox>
            <div v-if="filtros.dt_competencia">
              <v-date-picker
                v-model="filtros.dt_competencia_range"
                is-range
                class="text-center"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="flex justify-center items-center">
                    <input
                      :value="`${inputValue.start} - ${inputValue.end}`"
                      v-on="inputEvents.start"
                      class="form-control"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6 pt-4">
            <b>Fornecedor</b>

            <select-fornecedor
              :multiple="true"
              :showManager="false"
              @change="(ev) => (filtros.fornecedores = ev)"
            />
          </div>
          <div class="col-12 col-lg-6 pt-4">
            <b>Centro Custo</b>

            <select-centro-custo
              :multiple="true"
              :showManager="false"
              @change="(ev) => (filtros.centroCustos = ev)"
              :block="true"
            />
          </div>
          <div class="col-12 col-lg-6 pt-4">
            <b>Plano de Contas</b>
            <select-plano-contas
              :multiple="true"
              :showManager="false"
              @change="(ev) => (filtros.planoContas = ev)"
            />
          </div>
          <div class="col-12 col-lg-6 pt-4">
            <b>Carteira</b>

            <select-carteira
              :multiple="true"
              :showManager="false"
              @change="(ev) => (filtros.carteiras = ev)"
            />
          </div>
          <div class="col-12 col-lg-4 pt-4">
            <b>Tipo Lançamento</b>
            <b-form-radio-group v-model="filtros.tipo">
              <b-form-radio :value="0">Todos</b-form-radio>
              <b-form-radio :value="1">Receita</b-form-radio>
              <b-form-radio :value="-1">Despesa</b-form-radio>
            </b-form-radio-group>
          </div>
          <div class="col-12 col-lg-8 pt-4 text-right">
            <b-btn
              class="cursor mt-3"
              @click="showTiposHistorico = !showTiposHistorico"
            >
              <b
                >Tipos de Histórico <b-icon-chevron-down></b-icon-chevron-down
              ></b>
            </b-btn>
            <b-collapse v-model="showTiposHistorico">
              <select-tipo-h-istorico
                :multiple="true"
                :showManager="false"
                @change="(ev) => (filtros.tipoHistoricos = ev)"
                :show-clear="true"
              ></select-tipo-h-istorico>
            </b-collapse>
          </div>
        </div>
        <b-btn
          class="mt-4"
          block
          variant="success"
          @click="load"
        >
          FILTRAR</b-btn
        >
      </b-card-text>
    </b-card>

    <b-card
      class="mt-2"
      v-if="lista"
    >
      <div class="row">
        <div class="col-12 col-lg-2 px-0">
          <div
            v-if="lista && lista.saldo_anterior"
            style="font-size: 20px"
            class="card card-body rounded-lg bg-light text-center mr-1"
          >
            <b
              >Saldo Anterior
              <div style="font-size: 10px">
                {{ filtros.dt_lancamento_range.start | moment("DD/MM/YYYY") }}
              </div>
            </b>
            <div class="mt-2">
              {{ lista.saldo_anterior | currency }}
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-2 px-0">
          <div
            v-if="lista && lista.lista && lista.lista.length > 0"
            style="font-size: 20px"
            class="card card-body rounded-lg bg-light text-center mr-1"
          >
            <b
              >Despesas
              <div style="font-size: 10px">
                {{ filtros.dt_lancamento_range.start | moment("DD/MM/YYYY") }}
                até
                {{ filtros.dt_lancamento_range.end | moment("DD/MM/YYYY") }}
              </div>
            </b>
            <div class="mt-2">
              <span>{{ lista.despesas | currency }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-2 px-0">
          <div
            v-if="lista && lista.lista && lista.lista.length > 0"
            style="font-size: 20px"
            class="card card-body rounded-lg bg-light text-center mr-1"
          >
            <b
              >Receitas
              <div style="font-size: 10px">
                {{ filtros.dt_lancamento_range.start | moment("DD/MM/YYYY") }}
                até
                {{ filtros.dt_lancamento_range.end | moment("DD/MM/YYYY") }}
              </div>
            </b>
            <div class="mt-2">
              <span>{{ lista.receitas | currency }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-2 px-0">
          <div
            v-if="lista && lista.lista && lista.lista.length > 0"
            style="font-size: 20px"
            class="card card-body rounded-lg bg-light text-center mr-1"
          >
            <b
              >Total
              <div style="font-size: 10px">
                {{ filtros.dt_lancamento_range.start | moment("DD/MM/YYYY") }}
                até
                {{ filtros.dt_lancamento_range.end | moment("DD/MM/YYYY") }}
              </div>
            </b>
            <div class="mt-2">
              <span>{{ valorTotal | currency }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-2 px-0">
          <div
            v-if="lista && lista.saldo_atual"
            style="font-size: 20px"
            class="card card-body rounded-lg bg-light text-center mr-1"
          >
            <b
              >Saldo após
              <div style="font-size: 10px">
                em
                {{ filtros.dt_lancamento_range.end | moment("DD/MM/YYYY") }}
              </div></b
            >
            <div class="mt-2">
              {{ lista.saldo_final | currency }}
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-2 px-0">
          <div
            style="font-size: 20px"
            v-if="lista && lista.saldo_atual"
            class="card card-body rounded-lg bg-light text-center"
          >
            <b
              >Saldo atual
              <div style="font-size: 10px">
                em
                {{ new Date() | moment("DD/MM/YYYY") }}
              </div>
            </b>
            <div class="mt-2">
              {{ lista.saldo_atual | currency }}
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <b-card class="mt-2">
      <b-card-title>
        <div class="d-flex justify-content-between">
          <div>Agrupamentos</div>

          <b-btn
            variant="success"
            @click="addGrupo"
            v-if="!grupos || grupos == null"
            >Novo Agrupamento</b-btn
          >
        </div>
      </b-card-title>

      <b-card-text>
        <div
          class="text-center"
          v-if="!grupos || grupos == null"
        >
          Nenhum Agrupamento
        </div>
        <div
          class=""
          v-if="grupos"
        >
          <relatorio-movimentacao-financeiro-grupo
            :grupos="grupos"
            @updateGrupo="updateGrupos"
          />
        </div>
      </b-card-text>
    </b-card>

    <b-card class="mt-2">
      <b-card-title>
        <div
          class="d-flex justify-content-between"
          v-if="lista && lista.saldo_anterior"
        >
          <div>Listagem</div>
          <div>
            <export-excel :data="exportExcel()">
              <b-btn variant="secondary">
                <b-icon-file-excel /> Exportar
              </b-btn>
            </export-excel>
          </div>
        </div>
      </b-card-title>
      <b-card-text>
        <div v-if="!lista">Nenhum resultado</div>
        <div
          v-if="listaSelecionada && listaSelecionada.length > 0"
          class="card p-2"
        >
          Total Selecionado:
          {{
            listaSelecionada.reduce(
              (ret, vl) => ret + vl.valor_contabilizado,
              0
            ) | currency
          }}
        </div>
        <div v-if="grupos && lista">
          <div
            v-for="(gp, idx) in groupLista()"
            :key="idx"
          >
            <relatorio-movimentaca-financeira-group
              :gp="gp"
              @selectGroup="selectGroup"
              @sel="ss"
            />
          </div>
        </div>
        <div v-if="!grupos">
          <relatorio-movimentacao-financeira-detalhes
            @reload="load"
            :lista="lista.lista"
            v-if="lista"
          />
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import SelectCarteira from "../Financeiro/Bancario/SelectCarteira.vue";
import SelectCentroCusto from "../Financeiro/SelectCentroCusto.vue";
import SelectPlanoContas from "../Financeiro/SelectPlanoContas.vue";
import SelectFornecedor from "../Fornecedores/SelectFornecedor.vue";
import BancoMovimentoLib from "../../libs/BancoMovimentoLib";
import RelatorioMovimentacaoFinanceiroGrupo from "./RelatorioMovimentacaoFinanceiroGrupo.vue";

import RelatorioMovimentacaFinanceiraGroup from "./RelatorioMovimentacaFinanceiraGroup.vue";
import SelectTipoHIstorico from "../Financeiro/Bancario/SelectTipoHIstorico.vue";
import moment from "moment";
export default {
  components: {
    SelectFornecedor,
    SelectCentroCusto,
    SelectPlanoContas,
    SelectCarteira,
    RelatorioMovimentacaoFinanceiroGrupo,
    RelatorioMovimentacaoFinanceiraDetalhes: () =>
      import("./RelatorioMovimentacaoFinanceiraDetalhes.vue"),
    RelatorioMovimentacaFinanceiraGroup,
    SelectTipoHIstorico,
  },
  props: {},
  mounted() {
    if (this.user.tipo == "Administrador") {
      this.load();
    }
    //this.retornaSelecionados();
  },

  data() {
    return {
      showTiposHistorico: false,
      filtros: {
        relatorio: true,
        dt_vencimento: false,
        tipo: 0,
        dt_vencimento_range: {
          start: new Date(),
          end: new Date(),
        },
        dt_competencia: false,
        dt_competencia_range: {
          start: moment().startOf("month").toDate(),
          end: moment().endOf("month").toDate(),
        },
        dt_pagamento: false,
        dt_pagamento_range: {
          start: moment().startOf("month").toDate(),
          end: moment().endOf("month").toDate(),
        },
        dt_lancamento: true,
        dt_lancamento_range: {
          start: moment().startOf("month").toDate(),
          end: moment().endOf("month").toDate(),
        },
        dt_conciliado: false,
        dt_conciliado_range: {
          start: moment().startOf("month").toDate(),
          end: moment().endOf("month").toDate(),
        },
        fornecedores: [],
        centroCustos: [],
        planoContas: [],
        carteiras: [],
        tipoHistoricos: [],
      },
      loading: false,
      grupos: {
        nome: "Tipo Lançamento (rec/desp)",
        tipo: "tipo",
        campo: "tipo",
        campoExibicao: "tipoLancamento",
        subgrupo: {
          nome: "Plano de Contas",
          tipo: "id",
          campo: "plano_id",
          campoExibicao: "planoContas",
          subgrupo: null,
        },
      },
      lista: [],
      listaSelecionada: [],
    };
  },
  watch: {},
  computed: {
    valorTotal() {
      if (!this.lista || (this.lista && !this.lista.lista)) {
        return 0;
      }

      return this.lista.lista.reduce((ret, vl) => ret + vl.valor, 0);
    },
    valorTotalST() {
      if (!this.lista || (this.lista && !this.lista.lista)) {
        return 0;
      }
      // console.log("lissta", this.lista);
      return this.lista.lista.reduce(
        (ret, vl) =>
          ret +
          (vl.historico_contabilizado && vl.historico_contabilizado == 1
            ? vl.valor
            : 0),
        0
      );
    },
  },
  methods: {
    exportExcel() {
      const listFormated =
        this.lista && this.lista.lista
          ? this.lista.lista.map((item) => ({
              Código: item.id,
              "Data Lançamento": moment(item.data).format(
                "DD/MM/YYYY HH:mm:ss"
              ),
              "Data Competência": moment(item.dt_competencia).format(
                "DD/MM/YYYY HH:mm:ss"
              ),
              "Data Vencimento": moment(item.dt_vencimento).format(
                "DD/MM/YYYY"
              ),
              "Data Pagamento": moment(item.dt_pagamento).format(
                "DD/MM/YYYY HH:mm:ss"
              ),
              "Tipo Lançamento": item.tipoLancamento,
              "Plano de Contas": item.planoContas,
              "Centro de Custo": item.centro_custo,
              Fornecedor: item.fornecedor,
              Carteira: item.carteira,
              Histórico: item.historico,
              Observação: item.obs,
              Valor: this.formatFloatToReal(
                this.formatValorReal(item.valor.toString()),
                ""
              ),
            }))
          : [];

      return listFormated;
    },
    ss(ev) {
      console.log("ss", ev);
    },
    selectGroup(item) {
      console.log("selectGroup", item, this.lista);
      //
      if (
        this.listaSelecionada.some((x) => x.nomeExibicao == item.nomeExibicao)
        
      ) {
        this.listaSelecionada = this.listaSelecionada.filter(
          (x) => x.nomeExibicao != item.nomeExibicao
        );
      } else {
        this.listaSelecionada.push(item);
      }
      // console.log("selectGroup", item, this.lista);
      // while (
      //   item &&
      //   item.lista &&
      //   item.lista.length > 0 &&
      //   item.lista[0].nomeExibicao
      // ) {
      //   console.log("hasSubGrupo");
      //   for (let i of item.lista) {
      //     i.sel = true;
      //     this.selectGroup(i);
      //   }
      //   return;
      // }
      // if (item && item.lista && item.lista.length > 0) {

      // }
    },
    closeMovimento() {
      this.$bvModal.hide("modal-add-movimento");
    },
    openMovimento(item) {
      this.itemEdit = item;
      this.$bvModal.show("modal-add-movimento");
    },
    groupLista() {
      if (
        !this.lista ||
        (this.lista && this.lista.lista && this.lista.lista.length <= 0)
      ) {
        return [];
      }

      const groupBy = (arr, keys, fullGroups) => {
        // console.log("group", arr, keys, fullGroups)
        if (keys.length === 0) {
          return arr;
        }
        if (!arr || arr.length <= 0) {
          return [];
        }

        const [currentKey, ...remainingKeys] = keys;
        const grouped = arr.reduce((result, item) => {
          const key = item[currentKey];
          const existingGroup = result.find((group) => group.key === key);

          if (existingGroup) {
            existingGroup.lista.push(item);
            existingGroup.valor += item.valor;
            existingGroup.valor_contabilizado +=
              item.historico_contabilizado && item.historico_contabilizado == 1
                ? item.valor
                : 0;
          } else {
            result.push({
              sel: false,

              lista: [item],
              key,
              valor: item.valor,
              valor_contabilizado:
                item.historico_contabilizado &&
                item.historico_contabilizado == 1
                  ? item.valor
                  : 0,
              grupoNome: fullGroups.find((g) => g.campo == currentKey).nome,
              nomeExibicao:
                item[
                  fullGroups.find((g) => g.campo == currentKey).campoExibicao
                ],
            });
          }

          return result;
        }, []);

        return grouped
          .sort((a, b) => (a.nomeExibicao < b.nomeExibicao ? -1 : 1))
          .map((group) => ({
            ...group,
            lista: groupBy(group.lista, remainingKeys, fullGroups),
          }));
      };

      let groups = this.filterGroups();
      groups = [groups[groups.length - 1]];
      groups = groups.reduce((ret, vl) => {
        for (let g of vl) {
          ret.push(g);
        }
        return ret;
      }, []);
      const groupedLista = groupBy(
        this.lista.lista,
        groups.map((group) => group.campo),
        groups
      );
      // console.log("gplist", groupedLista);
      return groupedLista;
    },

    filterGroups() {
      const processObj = (obj, ancestors = []) => {
        let result = [];
        let currentGroup = [...ancestors, obj];
        result.push(currentGroup);
        console.log(`calcunado`, obj);
        if (obj.subgrupo) {
          result.push(...processObj(obj.subgrupo, currentGroup));
        }

        return result;
      };

      return this.grupos ? processObj(this.grupos) : [];
    },
    updateGrupos(grupo) {
      console.log(123);
      this.grupos = grupo;
      this.$forceUpdate();
    },
    async load() {
      if (
        this.user.tipo == "Gerente" &&
        (!this.filtros.centroCustos || this.filtros.centroCustos.length == 0)
      ) {
        this.showToast("warning", "Selecione um centro de custo");
        return;
        //this.filtros.centroCustos = this.user.configuracoes.centroCustos;
      }
      this.loading = true;
      this.lista = null;
      const res = await BancoMovimentoLib.getExtrato({
        ...this.filtros,
        somenteConciliado: true,
      });
      console.log(res);
      if (res && res.success) {
        this.lista = res.data;
      }
      this.loading = false;
    },
    addGrupo() {
      this.grupos = {
        nome: "",
        tipo: "",
        campo: "",
        campoExibicao: "",
        exibe: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor {
  cursor: pointer;
}
</style>
