<template>
  <div v-if="obj" class="mt-1" :style="`padding-left: ${nivel > 1 ? 60 : 0}px`">
    <div class="d-flex">
      <h3 class="mr-4">{{ nivel }}.</h3>
      <v-select
        style="flex: 1"
        :options="grupoOptions"
        v-model="obj"
        label="nome"
      ></v-select>
      <b-btn class="ml-2" variant="danger" @click="remover"
        ><b-icon-trash
      /></b-btn>
    </div>
    <div class="text-right pr-4" v-if="!obj.subgrupo">
      <b-btn variant="success" @click="addSub">Adicionar Subgrupo</b-btn>
    </div>

    <div v-if="obj && obj.subgrupo">
      <relatorio-movimentacao-financeiro-grupo
        :nivel="nivel + 1"
        :grupos="obj.subgrupo"
        @updateGrupo="updtSubGrupo"
      ></relatorio-movimentacao-financeiro-grupo>
    </div>
  </div>
</template>

<script>
export default {
  name: "Group" + Math.random(),
  components: {
    RelatorioMovimentacaoFinanceiroGrupo: () =>
      import("./RelatorioMovimentacaoFinanceiroGrupo.vue"),
  },
  props: {
    grupos: Object,
    nivel: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    if (this.grupos) {
      this.obj = this.grupos;
    }
  },
  data() {
    return {
      obj: null,
      grupoOptions: [
        {
          nome: "Lançamento - Dia",
          tipo: "data-dia",
          campo: "dt_competencia",
          campoExibicao: "dt_competencia",
        },
        {
          nome: "Lançamento - Mes",
          tipo: "data-mes",
          campo: "mesAno",
          campoExibicao: "data",
        },
        {
          nome: "Conta / Carteira",
          tipo: "id",
          campo: "carteira",
          campoExibicao: "carteira",
        },
        {
          nome: "Fornecedor",
          tipo: "id",
          campo: "fornecedor_id",
          campoExibicao: "fornecedor",
        },
        {
          nome: "Plano de Contas",
          tipo: "id",
          campo: "planoContas",
          campoExibicao: "planoContas",
        },
        {
          nome: "Centro de Custo",
          tipo: "id",
          campo: "centro_custo",
          campoExibicao: "centro_custo",
        },
        {
          nome: "Historico",
          tipo: "id",
          campo: "historico",
          campoExibicao: "historico",
        },
        {
          nome: "Tipo Lançamento (rec/desp)",
          tipo: "tipo",
          campo: "tipo",
          campoExibicao: "tipoLancamento",
        },
      ],
    };
  },
  watch: {
    obj: {
      handler() {
        this.$forceUpdate();
        console.log("change", this.obj);
        this.$emit("updateGrupo", this.obj);
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    updtSubGrupo(subgrupo) {
      this.obj.subgrupo = subgrupo;
      this.$forceUpdate();
      console.log(this.obj.subgrupo);
      this.$emit("updateGrupo", this.obj);
    },
    addSub() {
      this.obj.subgrupo = {
        nome: "",
        tipo: "",
        campo: "",
        campoExibicao: "",
      };
      this.$forceUpdate();
      this.$emit("updateGrupo", this.obj);

      console.log(this.obj.subgrupo);
    },
    remover() {
      this.obj = null;
      this.$forceUpdate();
      this.$emit("updateGrupo", this.obj);
    },
  },
};
</script>

<style lang="scss" scoped></style>
